<template>
  <div class="about">
    <h1 class="py-6"> <v-icon large color="red">mdi-comment-question-outline</v-icon> About us </h1>
   <v-card class="pa-8 my-4">
    <h2>Event-Infrastructure Deployment Center</h2>
    <p> is a frontend for 
    <a href="https://github.com/apeunit/LaunchControlD">https://github.com/apeunit/LaunchControlD</a>
    <h2>Developed by</h2>
    <p>
    <a href='https://apeunit.com/'>Ape Unit GmbH</a>
    <p>
    <h2>Source code</h2>
    <a href='https://github.com/apeunit/LaunchControlCenter'>https://github.com/apeunit/LaunchControlCenter</a>
    </v-card>
    <v-card class="pa-8 my-4">
    <h2>Imprint</h2>
    <p>
      Ape Unit GmbH<br>
      Waldemarstraße 38<br>
      10999 Berlin <br>
    </p>
     </v-card>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: 'About'
}
</script>
